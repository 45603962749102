@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css');
@import url('https://use.typekit.net/cxz6nkg.css');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,900&display=swap');

* {
  font-family: 'Pretendard';
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

html, body {
  width: 100%;
  /* height: 100%; */
  @media only screen and (min-width: 1300px) {
    min-width: 1340px;
    min-height: 100vh;
  }
}

#root, .wrapper {
  width: 100%;
  position: relative;
}

.active {
  color: #DC140A !important;
}

/* .active div:first-child > span {
  color: #DC140A !important;
} */

@media only screen and (max-width: 1299px) {
  html, body {
    -ms-overflow-style: none;
    overflow-x: hidden;
    max-width: 100%;
    min-width: 360px;
  }
  ::-webkit-scrollbar {
    display: none;
  }
}