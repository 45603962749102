@keyframes modalOpen {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }
    90% {
        opacity: 0.9;
        transform: translateY(-40px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes modalClose {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(100%);
    }
}

@media only screen and (max-width: 1299px) {
    .wrap {
        width: 100vw;
        animation-fill-mode: both;
        position: fixed;
        z-index: 9999;
        background-color: #FFFFFF;
        padding: 100px 20px 86px;
        overflow-x: hidden;
        top: 0;
        height: 100%;
        overflow-y: auto;
    }
    
    .open {
        animation: modalOpen 0.5s ease-in-out;
    }
    
    .close {
        animation: modalClose 0.5s ease-in-out;
    }
    
    .containerTop {
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        position: relative;
    }
    
    .imgContainer {
        width: 208px;
    }
    
    .imgContainer > img {
        width: inherit;
        display: block;
    }

    .infoContainer {
        display: flex;
        flex-flow: column;
        justify-content: center;
        flex-shrink: 0;
    }
    
    .infoContainer > span {
        display: block;
        text-align: right;
    }
    
    .infoEname {
        color: #A2A2A2;
        font-size: 12px;
        font-weight: 500;   
        opacity: 1;
    }
    
    .infoEname.hide {
        visibility: hidden;
    }
    
    .infoKname {
        color: #222222;
        font-size: 20px;
        font-weight: bold;
        margin: 0 0 14px;
    }
    
    .infoBirth {
        color: #222222;
        font-size: 12px;
    }
    
    .infoHr {
        position: absolute;
        width: calc(100vw - 200px);
        border: none;
        height: 0;
        border-top: 1px solid #DC140A;
        top: 51%;
        transform: translateY(-51%);
        right: 0;
    }
    
    .exit {
        position: absolute;
        right: 0;
        top: -70px;
        cursor: pointer;
        width: 28px;
    }
    
    .containerMiddle {
        margin: 20px 0 40px;
        padding: 0 calc((100vw - 1317px) / 2);
        display: flex;
        gap: 20px;
        overflow-x: scroll;
    }
    
    .containerMiddle::-webkit-scrollbar {
        display: none;
    }
    
    .imgItem {
        width: 100px;
        height: 100px;
        flex-shrink: 0;
        cursor: pointer;
        overflow: hidden;
        position: relative;
    }
    
    .imgItem.selected {
        border: 2px solid #DC140A;
    }
    
    .imgItem > img {
        width: inherit;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    
    .careerContainer {
        width: 100vw;
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(auto-fill, 320px);
        grid-auto-rows: 1px;
    }
    
    .careerItem {
        display: flex;
        flex-flow: column;
        gap: 16px;
        width: 320px;
    }
    
    .careerTheme {
        display: flex;
        gap: 6px;
        align-items: center;
    }
    
    .careerTheme > span {
        font-size: 20px;
        font-weight: 500;
        color: #222222
    }
    
    .careerCount {
        min-width: 33px;
        display: flex;
        justify-content: center;
        padding: 4px 10px;
        background-color: #DC140A;
        border-radius: 16px;
        color: #FFFFFF;
        font-size: 12px;
    }
    
    .careerContent {
        font-size: 12px;
        color: #434343;
        line-height: 20px;
    }
    
    .careerContent > span {
        display: block;
    }
}

@media only screen and (min-width: 1300px) {
    .wrap {
        width: 100%;
        animation-fill-mode: both;
        position: fixed;
        z-index: 9999;
        background-color: #FFFFFF;
        padding: 120px 0 240px;
        overflow-x: hidden;
        top: 0;
        height: 100%;
        overflow-y: auto;
    }
    
    .open {
        animation: modalOpen 0.5s ease-in-out;
    }
    
    .close {
        animation: modalClose 0.5s ease-in-out;
    }
    
    .containerTop {
        width: 1300px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        position: relative;
    }
    
    .imgContainer {
        width: 640px;
        height: 960px;
    }
    
    .imgContainer > img {
        width: inherit;
    }

    .infoContainer {
        display: flex;
        flex-flow: column;
        justify-content: center;
    }
    
    .infoContainer > span {
        display: block;
        text-align: right;
    }
    
    .infoEname {
        color: #434343;
        font-size: 24px;
        font-weight: 500;   
        opacity: 0.47;
    }
    
    .infoEname.hide {
        visibility: hidden;
    }
    
    .infoKname {
        color: #222222;
        font-size: 56px;
        font-weight: bold;
        margin: 4px 0 24px;
    }
    
    .infoBirth {
        color: #222222;
        font-size: 24px;
    }
    
    .infoHr {
        position: absolute;
        width: 750px;
        border: none;
        height: 0;
        border-top: 1px solid #DC140A;
        top: 53%;
        transform: translateY(-53%);
        right: 0;
    }
    
    .exit {
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
    }
    
    .containerMiddle {
        margin: 80px 0 82px;
        padding: 0 calc((100vw - 1317px) / 2);
        display: flex;
        gap: 80px;
        overflow-x: scroll;
    }
    
    .containerMiddle::-webkit-scrollbar {
        display: none;
    }
    
    .imgItem {
        width: 280px;
        height: 280px;
        flex-shrink: 0;
        cursor: pointer;
        overflow: hidden;
        position: relative;
    }
    
    .imgItem.selected {
        border: 2px solid #DC140A;
    }
    
    .imgItem > img {
        width: inherit;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    
    .careerContainer {
        width: 1300px;
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(auto-fill, 400px);
        grid-auto-rows: 1px;
    }
    
    .careerItem {
        display: flex;
        flex-flow: column;
        gap: 28px;
        width: 400px;
    }

    .careerTheme {
        display: flex;
        gap: 6px;
        align-items: center;
    }
    
    .careerTheme > span {
        font-size: 34px;
        font-weight: 500;
        color: #222222
    }
    
    .careerCount {
        min-width: 43px;
        display: inline-block;
        padding: 4px 16px;
        background-color: #DC140A;
        border-radius: 16px;
        color: #FFFFFF;
        font-size: 20px;
    }
    
    .careerContent {
        font-size: 26px;
        color: #434343;
        line-height: 44px;
    }
    
    .careerContent > span {
        display: block;
    }
}