@keyframes divUp {
  0% {
      opacity: 0;
      transform: translateY(40px);
  }
  100% {
      opacity: 1;
      transform: translateY(0);
  }
}

@media only screen and (max-width: 1299px) {
  .container {
    margin-top: 60px;
    width: 156px;
    height: 20px;
    display: flex;
    animation: divUp 0.6s ease-in-out;
    animation-fill-mode: both;
    animation-delay: 0.6s;
    margin-left: 20px;
  }
  
  .arrow {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  
  .numWrap {
    width: 116px;
    height: inherit;
    position: relative;
    overflow: hidden;
  }
  
  .num {
    width: 100%;
    display: flex;
    gap: 4px;
    scroll-behavior: smooth;
    position: absolute;
    transition: all, 0.3s;
  }
  
  .numItem {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #A2A2A2;
    font-size: 10px;
    cursor: pointer;
    flex-shrink: 0;
  }
  
  .select {
    background-color: #DC140A;
    color: #F4F4F4;
    border-radius: 10px;
    font-weight: 600;
    cursor: default;
  }

  .navBox {
    width: 100%;
    height: 54px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    animation: divUp 0.6s ease-in-out;
    animation-fill-mode: both;
  }

  .navBoxPageText {
    color: #222222;
    font-weight: 600;
    font-size: 17px;
    text-transform: uppercase;
    margin: 0;
  }

  .webNavBoxPathContainer {
    display: none;
  }

  .mobileNavBoxPathContainer {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .navBoxPathText {
    color: #a2a2a2;
    font-size: 11px;
    text-transform: capitalize;
  }

  .navBoxPathSeparator {
    width: 0; 
    height: 0; 
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-left: 5px solid #a2a2a2;
  }

  .popupWrap {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.7);
  }
  .popupContainer {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 40px);
    width: 320px;
    background-color: #fff;
  }

  .popupHeader {      
    width: 100%;
    height: 40px;
    background-color: #E50019;
    padding: 14px;
    display: flex;
    justify-content: right;
    align-items: center;
  }

  .popupHeader > img {
    cursor: pointer;
  }

  .popupContent {
    width: 100%;
    padding: 25px;
  }

  .popupcnt {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #E50019;
    border-bottom: 1px solid #e9ecef;
    padding: 25px;
  }

  .popupcnt img {
  }

  .popupcnt h2 {
    font-size: 16px;
    color: #222222;
    margin: 40px 0 20px;
  }

  .popupcnt a {
    width: 134px;
    height: 39px;
    line-height: 40px;
    background-color: #E63131;
    font-size: 12px;
    border-radius: 36px;
    color: #fff;
    font-weight: bold;
    text-align: center;
  }

  .popupcnt > img {
    width: inherit;
    display: block;
  }

  .popupFooter {
    width: 100%;
    height: 24px;
    background-color: #F4F4F4;
    text-align: right;
  }

  .popupFooter > span {
    color: #6F6F6F;
    font-size: 9px;
    letter-spacing: -0.09px;
    padding-right: 12px;
    line-height: 24px;
    text-decoration: underline;
    cursor: pointer;
  }
}

@media only screen and (min-width: 1300px) {
  .container {
      margin-top: 80px;
      width: 256px;
      height: 32px;
      display: flex;
      animation: divUp 0.6s ease-in-out;
      animation-fill-mode: both;
      animation-delay: 0.6s;
    }
    
    .arrow {
      width: 32px;
      height: 32px;
      cursor: pointer;
    }
    
    .numWrap {
      width: 192px;
      height: inherit;
      position: relative;
      overflow: hidden;
    }
    
    .num {
      width: 100%;
      display: flex;
      gap: 8px;
      scroll-behavior: smooth;
      position: absolute;
      transition: all, 0.3s;
    }
    
    .numItem {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      color: #A2A2A2;
      font-size: 22px;
      cursor: pointer;
      flex-shrink: 0;
    }
    
    .select {
      background-color: #DC140A;
      color: #F4F4F4;
      border-radius: 16px;
      font-weight: 600;
      cursor: default;
    }

    .navBox {
      width: 100%;
      height: 96px;
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      animation: divUp 0.6s ease-in-out;
      animation-fill-mode: both;
    }
  
    .navBoxPageText {
      color: #222222;
      font-weight: 600;
      font-size: 32px;
      text-transform: uppercase;
      margin: 0;
    }
  
    .mobileNavBoxPathContainer {
      display: none;
    }

    .webNavBoxPathContainer {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .navBoxPathText {
      color: #a2a2a2;
      font-size: 18px;
      text-transform: capitalize;
    }
  
    .navBoxPathSeparator {
      width: 0; 
      height: 0; 
      border-top: 4.5px solid transparent;
      border-bottom: 4.5px solid transparent;
      border-left: 8px solid #a2a2a2;
    }

    .popupWrap {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 9999;
      background-color: rgba(0, 0, 0, 0.7);
    }

    .popupContainer {
      position: absolute;
      left: 10%;
      width: 550px;
      background-color: #fff;
    }

    .popupHeader {      
      width: 100%;
      height: 56px;
      background-color: #E50019;
      padding: 16px;
      display: flex;
      justify-content: right;
      align-items: center;
    }

    .popupHeader > img {
      cursor: pointer;
    }
    
    .popupContent {
      width: 100%;
      padding: 30px;
    }

    .popupcnt {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-top: 1px solid #E50019;
      border-bottom: 1px solid #e9ecef;
      padding: 50px;
    }

    .popupcnt img {
      width: 100%;
    }

    .popupcnt h2 {
      font-size: 28px;
      color: #222222;
      margin: 50px 0 30px;
    }

    .popupcnt a {
      width: 180px;
      height: 51px;
      line-height: 51px;
      background-color: #E63131;
      font-size: 16px;
      border-radius: 36px;
      color: #fff;
      font-weight: bold;
      text-align: center;
    }

    .popupcnt > img {
      width: inherit;
      display: block;
    }

    .popupFooter {
      width: 100%;
      height: 40px;
      background-color: #F4F4F4;
      text-align: right;
    }

    .popupFooter > span {
      color: #6F6F6F;
      font-size: 14px;
      letter-spacing: -0.14px;
      padding-right: 16px;
      line-height: 40px;
      text-decoration: underline;
      cursor: pointer;
    }
}