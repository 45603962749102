@keyframes divUp {
    0% {
        opacity: 0;
        transform: translateY(40px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@media only screen and (max-width: 1299px) {
    .wrap {
        width: 100vw;
        padding: 84px 0 120px;
        min-height: calc(100vh - 231px);
    }

    .container {
        width: 100vw;
        margin: 0 auto;
    }

    .categoryContainer {
        text-align: center;
        animation: divUp 0.6s ease-in-out;
        animation-fill-mode: both;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 13px;
    }

    .categoryContainer span:nth-child(1) {
        color: #222222;
        font-size: 32px;
        font-weight: bold;
    }

    .categoryContainer span:nth-child(2) {
        display: inline-block;
        padding: 3px 11px;
        background-color: #DC140A;
        border-radius: 16px;
        color: #FFFFFF;
        font-size: 12px;
        font-weight: bold;
        margin-top: auto;
        margin-bottom: 7px;
    }

    .artistContainer {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
        padding: 0 calc((100vw - ((max(160px, min(44.4vw, 310px)) * 2) + 8px)) / 2);
        justify-content: flex-start;
        transition: 0.3s ease-out;
    }

    .musicianContainer {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
        padding: 0 calc((100vw - ((max(160px, min(44.4vw, 310px)) * 2) + 8px)) / 2);
        justify-content: flex-start;
        transition: 0.3s ease-out;
    }

    .artistItem {
        display: flex;
        flex-flow: column;
        gap: 8px;
        cursor: pointer;
        animation: divUp 0.6s ease-in-out;
        animation-fill-mode: both;
    }

    .artistItemImg,
    .artistItemImg > img
    {
        width: max(160px, min(44.4vw, 310px));
        height: max(160px, min(44.4vw, 310px));
        transition: 0.3s ease-out;
        display: block;
        object-fit: cover;
        object-position: center 10%;
    }

    .artistItemImg {
        overflow: hidden;
        position: relative;
    }

    .artistItem:hover .artistItemImg > img {
        transform: scale(1.1);
    }

    .artistItemNameContainer {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 38px;
        background-color: #222222;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .artistItemName {
        position: relative;
        color: #f4f4f4;
        font-size: 14px;
        font-weight: 600;
    }

    .artistItemName > span {
        display: inline-block;
        position: relative;
    }

    .artistItem:hover > .artistItemName {
        color: #DC140A;
    }

    .musicianItem {
        display: flex;
        flex-flow: column;
        gap: 10px;
        cursor: pointer;
        animation: divUp 0.6s ease-in-out;
        animation-fill-mode: both;
    }

    .musicianItemImg,
    .musicianItemImg > img
    {
        width: max(160px, min(44.4vw, 310px));
        height: max(160px, min(44.4vw, 310px));
        transition: 0.3s ease-out;
        display: block;
        object-fit: cover;
        object-position: center 10%;
    }

    .musicianItemImg {
        overflow: hidden;
    }

    .musicianItem:hover .musicianItemImg > img {
        transform: scale(1.1);
    }

    .musicianItemName {
        color: #222222;
        font-size: 12px;
        font-weight: 600;
        text-align: center;
    }

    .musicianItemName > span {
        display: inline-block;
        position: relative;
    }

    .musicianItem:hover > .musicianItemName {
        color: #DC140A;
    }

    .musicianItem:hover .plusShape {
        opacity: 1;
        visibility: visible;
    }

    .musicianItem:hover .plusShape > hr:nth-child(1) {
        transform: rotate(270deg);
    }
    .musicianItem:hover .plusShape > hr:nth-child(2) {
        transform: rotate(360deg);
    }

    .plusShape {
        position: absolute;
        top: 50%;
        right: -17px;
        width: 9px;
        height: 9px;
        transform: translateY(-50%);
        transition: 0.3s ease-out;
    }

    .plusShape > div {
        width: 9px;
        height: 1px;
        background-color: #f4f4f4;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        transition: 0.3s ease-out;
    }

    .plusShape > div:nth-child(2) {
        transform: translateY(-50%) rotate(90deg);
    }

    .artistNodataContainer {
        margin-bottom: 160px;
        animation: divUp 0.6s ease-out;
        animation-fill-mode: both;
        animation-delay: 0.6s;
        width: 100%;
    }

    .musicianNodataContainer {
        margin-bottom: 160px;
        animation: divUp 0.6s ease-out;
        animation-fill-mode: both;
        animation-delay: 0.6s;
        width: 100%;
    }

    .productionNoData {
        color: #222222;
        font-size: 16px;
        font-weight: 600;
        display: block;
        text-align: center;
        margin-top: 110px;
    }

    .contentContainer {
        margin: 0 auto;
        height: auto;
        animation: divUp 0.6s ease-in-out;
        animation-delay: 0.6s;
        animation-fill-mode: both;
        padding: 12px;
    }

    .news {
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(auto-fill, 166px);
        grid-auto-rows: 1px;
    }

    .newsitem {
        margin: 0 8px;
        cursor: pointer;
        position: relative;
        animation: divUp 0.6s ease-in-out;
        animation-fill-mode: both;
    }

    .newsitem:hover > .imgContainer > img {
        transform: scale(1.1);
    }

    .newsitem > .imgContainer > img {
        width: 152px;
        transition: 0.3s ease-in-out;
        overflow: hidden;
        display: block;
    }

    .small {
        grid-row-end: span 153;
    }

    .big {
        grid-row-end: span 299;
    }

    .small > .imgContainer > img {
        height: 102px;
    }

    .big .imgContainer > img {
        height: 228px;
    }

    .imgContainer {
        width: 152px;
        overflow: hidden;
    }

    .tagContainer {
        color: #DC140A;
        font-size: 10px;
        font-weight: 500;
        margin: 4px 0;
        display: -webkit-box;
        width: 152px;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .tagContainer > span {
        margin-right: 8px;
    }

    .themeContainer {
        overflow: hidden;
        color: #1C1C1C;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
    }

    .themeContainer > span {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .pagenationContainer {
        width: 100vw;
        margin: 0 auto;
    }

    .moreContainer {
        width: 100vw;
        margin: 0 auto;
        animation: divUp 0.6s ease-in-out;
        animation-delay: 0.6s;
        animation-fill-mode: both;
        padding: 0 20px;
    }

    .moreThemeContainer {
        border-top: 2px solid #DC140A;
        border-bottom: 1px solid #DDDDDD;
        padding: 14px 0;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .moreTheme {
        color: #222222;
        font-size: 20px;
        line-height: 28px;
        font-weight: bold;
    }

    .moreDate {
        color: #A2A2A2;
        font-size: 12px;
        font-weight: 300;
        flex-shrink: 0;
    }

    .moreContentContainer {
        padding: 30px 0;
        display: flex;
        flex-flow: column;
        gap: 40px;
    }

    .moreContent {
        color: #434343;
        font-size: 12px;
        line-height: 20px;
        white-space: pre-line;
    }
    .moreContentContainer div:nth-child(2) {
        max-width: 1200px;
        overflow: hidden;
    }
    .moreContentContainer div:nth-child(2) > img {
        max-width: 100%;
    }

    .moreImg {
        width: 152px;
    }

    .bigSize {
        height: 228px;
    }

    .smallSize {
        height: 102px;
    }

    .backToList {
        color: #FFFFFF;
        background-color: #DC140A;
        border-radius: 16px;
        font-size: 16px;
        font-weight: bold;
        padding: 0 16px;
        display: flex;
        width: 71px;
        height: 29px;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        gap: 8px;
    }

    .backToList > img {
        width: 6px;
    }

    .notice {
        width: calc(100vw - 40px);
        margin: 0 auto;
        border-top: 2px solid #DC140A;
        padding: 0 10px;
    }

    .noticeItem {
        display: flex;
        flex-flow: column;
        gap: 4px;
        border-bottom: 1px solid #DDDDDD;
        padding: 14px 0;
    }

    .noticeItemTop {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 4px;
    }

    .noticeItemTheme {
        color: #1C1C1D;
        font-size: 12px;
        font-weight: 600;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .noticeItemDate {
        flex-shrink: 0;
        color: #A2A2A2;
        font-size: 12px;
        font-weight: 300;
    }

    .noticeItemDate > span {
        font-size: 12px;
    }

    .noticeItemBottom {
        width: 785px;
    }

    .noticeItemContent {
        color: #434343;
        font-size: 12px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .buttonContainer {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        flex-direction: column;
        gap: 20px;
    }

    .noticeDownload {
        display: flex;
        gap: 16px;
        overflow-x: scroll !important;
    }

    .noticeDownloadBtn {
        background-color: #F4F4F4;
        height: 25px;
        display: flex;
        border-radius: 24px;
        gap: 8px;
        justify-content: space-between;
        align-items: center;
        padding: 0 16px;
        color: #222222;
        font-size: 12px;
        cursor: pointer;
        max-width: 260px;
    }

    .noticeDownloadBtn > img {
        width: 10px;
    }

    .spanSplit {
        display: flex;
        min-width: 0;
    }

    .spanSplit > span:first-child {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .spanSplit > span:last-child {
        flex-shrink: 0;
    }
}

@media only screen and (min-width: 1300px) {
    .wrap {
        width: 100%;
        padding: 148px 0 240px;
    }

    .container {
        width: 1300px;
        margin: 0 auto;
    }

    .categoryContainer {
        text-align: center;
        animation: divUp 0.6s ease-in-out;
        animation-fill-mode: both;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
    }

    .categoryContainer span:nth-child(1) {
        color: #222222;
        font-size: 64px;
        font-weight: bold;
    }

    .categoryContainer span:nth-child(2) {
        display: inline-block;
        padding: 4px 16px;
        background-color: #DC140A;
        border-radius: 16px;
        color: #FFFFFF;
        font-size: 20px;
        font-weight: bold;
        margin-top: auto;
        margin-bottom: 19px;
    }

    .artistContainer {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        justify-content: flex-start;
        transition: 0.3s ease-out;
    }

    .musicianContainer {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        justify-content: flex-start;
        transition: 0.3s ease-out;
    }

    .artistItem {
        display: flex;
        flex-flow: column;
        gap: 8px;
        cursor: pointer;
        animation: divUp 0.6s ease-in-out;
        animation-fill-mode: both;
    }

    .artistItemImg,
    .artistItemImg > img
    {
        width: 310px;
        height: 310px;
        transition: 0.3s ease-out;
        display: block;
        object-fit: cover;
        object-position: center 10%;
    }

    .artistItemImg {
        overflow: hidden;
    }

    .artistItem:hover .artistItemImg > img {
        transform: scale(1.1);
    }

    .artistItemNameContainer {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 62px;
        background-color: #222222;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .artistItemName {
        position: relative;
        color: #f4f4f4;
        font-size: 26px;
        font-weight: 600;
    }

    .artistItemName > span {
        display: inline-block;
        position: relative;
    }

    .artistItem:hover > .artistItemName {
        color: #DC140A;
    }

    .artistItem:hover .plusShape {
        opacity: 1;
        visibility: visible;
        transform: translateY(-50%) rotate(270deg);
    }

    .musicianItem {
        display: flex;
        flex-flow: column;
        gap: 8px;
        cursor: pointer;
        animation: divUp 0.6s ease-in-out;
        animation-fill-mode: both;
    }

    .musicianItemImg,
    .musicianItemImg > img
    {
        width: 310px;
        height: 310px;
        transition: 0.3s ease-out;
        display: block;
        object-fit: cover;
        object-position: center 10%;
    }

    .musicianItemImg {
        overflow: hidden;
    }

    .musicianItem:hover .musicianItemImg > img {
        transform: scale(1.1);
    }

    .musicianItemName {
        color: #222222;
        font-size: 26px;
        font-weight: 500;
        text-align: center;
    }

    .musicianItemName > span {
        display: inline-block;
        position: relative;
    }

    .musicianItem:hover > .musicianItemName {
        color: #DC140A;
    }

    .musicianItem:hover .plusShape {
        opacity: 1;
        visibility: visible;
    }

    .musicianItem:hover .plusShape > hr:nth-child(1) {
        transform: rotate(270deg);
    }
    .musicianItem:hover .plusShape > hr:nth-child(2) {
        transform: rotate(360deg);
    }

    .plusShape {
        position: absolute;
        top: 50%;
        right: -24px;
        width: 16px;
        height: 16px;
        transform: translateY(-50%);
        transition: 0.3s ease-out;
        visibility: hidden;
        opacity: 0;
    }

    .plusShape > div {
        width: 16px;
        height: 2px;
        background-color: #C93121;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        transition: 0.3s ease-out;
    }

    .plusShape > div:nth-child(2) {
        transform: translateY(-50%) rotate(90deg);
    }

    .artistNodataContainer {
        margin-bottom: 160px;
        animation: divUp 0.6s ease-out;
        animation-fill-mode: both;
        animation-delay: 0.6s;
        width: 100%;
    }

    .musicianNodataContainer {
        margin-bottom: 160px;
        animation: divUp 0.6s ease-out;
        animation-fill-mode: both;
        animation-delay: 0.6s;
        width: 100%;
    }

    .productionNoData {
        color: #222222;
        font-size: 40px;
        font-weight: 600;
        display: block;
        text-align: center;
        margin-top: 120px;
    }

    .contentContainer {
        margin: 0 auto;
        height: auto;
        animation: divUp 0.6s ease-in-out;
        animation-delay: 0.6s;
        animation-fill-mode: both;
    }

    .news {
        width: 1350px;
        display: grid;
        grid-template-columns: repeat(auto-fill, 450px);
        grid-auto-rows: 1px;
    }

    .newsitem {
        margin: 0 25px;
        cursor: pointer;
        position: relative;
        animation: divUp 0.6s ease-in-out;
        animation-fill-mode: both;
    }

    .newsitem:hover > .imgContainer > img {
        transform: scale(1.1);
    }

    .newsitem > .imgContainer > img {
        width: 400px;
        transition: 0.3s ease-in-out;
        overflow: hidden;
        display: block;
    }

    .small {
        grid-row-end: span 411;
    }

    .big {
        grid-row-end: span 745;
    }

    .small > .imgContainer > img {
        height: 266px;

    }

    .big .imgContainer > img {
        height: 600px;
    }

    .imgContainer {
        width: 400px;
        overflow: hidden;
    }

    .tagContainer {
        color: #DC140A;
        font-size: 18px;
        font-weight: 500;
        margin: 16px 0 8px;
        display: -webkit-box;
        width: 400px;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .tagContainer > span {
        margin-right: 16px;
    }

    .themeContainer {
        overflow: hidden;
        color: #1C1C1C;
        font-size: 22px;
        font-weight: 600;
        line-height: 30px;
    }

    .themeContainer > span {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .pagenationContainer {
        width: 1300px;
        margin: 0 auto;
    }

    .moreContainer {
        width: 1300px;
        margin: 0 auto;
        animation: divUp 0.6s ease-in-out;
        animation-delay: 0.6s;
        animation-fill-mode: both;
    }

    .moreThemeContainer {
        border-top: 2px solid #DC140A;
        border-bottom: 1px solid #DDDDDD;
        padding: 48px 33px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
    }

    .moreTheme {
        color: #222222;
        font-size: 40px;
        font-weight: 600;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .moreDate {
        color: #A2A2A2;
        font-size: 26px;
        flex-shrink: 0;
    }

    .moreContentContainer {
        padding: 80px 33px;
        display: flex;
        flex-flow: column;
        gap: 80px;
    }

    .moreContent {
        color: #434343;
        font-size: 26px;
        line-height: 44px;
        white-space: pre-line;
    }
    .moreContentContainer div:nth-child(2) {
        max-width: 1200px;
        overflow: hidden;
    }
    .moreContentContainer div:nth-child(2) > img {
        max-width: 100%;
    }

    .moreImg {
        width: 400px;
    }

    .bigSize {
        height: 600px;
    }

    .smallSize {
        height: 266px;
    }

    .backToList {
        color: #FFFFFF;
        background-color: #DC140A;
        border-radius: 24px;
        font-size: 26px;
        font-weight: bold;
        padding: 0 32px;
        display: flex;
        width: 129px;
        height: 48px;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }

    .notice {
        width: 1300px;
        margin: 0 auto;
        border-top: 2px solid #DC140A;
    }

    .noticeItem {
        padding: 40px 32px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #DDDDDD;
    }

    .noticeItemTop {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 16px;
        width: 785px;
    }

    .noticeItemTheme {
        color: #1C1C1D;
        font-size: 26px;
        font-weight: bold;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .noticeItemDate {
        flex-shrink: 0;
        color: #A2A2A2;
        font-size: 26px;
        font-weight: 300;
    }

    .noticeItemContent {
        color: #434343;
        font-size: 18px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .buttonContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .noticeDownload {
        display: flex;
        gap: 16px;
    }

    .noticeDownloadBtn {
        background-color: #F4F4F4;
        height: 49px;
        display: flex;
        border-radius: 24px;
        gap: 16px;
        justify-content: space-between;
        align-items: center;
        padding: 0 32px;
        color: #222222;
        font-size: 26px;
        cursor: pointer;
        max-width: 260px;
    }

    .spanSplit {
        display: flex;
        min-width: 0;
    }

    .spanSplit > span:first-child {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .spanSplit > span:last-child {
        flex-shrink: 0;
    }
}

@media only screen and (min-width: 672px) and (max-width: 1002px) {
    .artistContainer {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        justify-content: flex-start;
        transition: 0.3s ease-out;
        padding: 0 calc((100vw - 640px) / 2);
    }
    .musicianContainer {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        justify-content: flex-start;
        transition: 0.3s ease-out;
        padding: 0 calc((100vw - 640px) / 2);
    }
}

@media only screen and (min-width: 1003px) and (max-width: 1299px) {
    .artistContainer {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        justify-content: flex-start;
        transition: 0.3s ease-out;
        padding: 0 calc((100vw - 970px) / 2);
    }
    .musicianContainer {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        justify-content: flex-start;
        transition: 0.3s ease-out;
        padding: 0 calc((100vw - 970px) / 2);
    }
}