@media only screen and (max-width: 1299px) {
    .mobileHeaderWrap {
        position: relative;
        padding: 0 20px;
    }

    .mobileHeaderInnerWrap {
        display: block;
        width: 100%;
        height: 46px;
        background-color: #FFFFFF;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: solid 1px #E6E6E6;
    }

    .mobileHeaderLogo {
        display: block;
        height: 46px;
    }

    .mobileHeaderBtn {
        display: flex;
        flex-flow: column;
        justify-content: center;
        gap: 4px;
        width: 20px;
        height: 20px;
        cursor: pointer;
    }

    .mobileHeaderBtn > div {
        border: none;
        width: 20px;
        background-color: #222222;
        height: 2px;
    }

    .mobileHeader {
        position: absolute;
        top: 0;
        right: 0;
        width: 284px;
        height: 640px;
        background-color: #222222;
        display: flex;
        flex-direction: column;
        transition: 0.3s ease-out;
        opacity: 0;
    }

    .mobileHeaderClose {
        position: absolute;
        top: 30px;
        right: 22px;
        width: 18px;
        height: 18px;
        cursor: pointer;
    }

    .mobileHeaderClose > div:nth-child(1) {
        position: absolute;
        top: 47%;
        left: -20%;
        width: 25px;
        height: 1px;
        background-color: #FFFFFF;
        transform: rotate(45deg);
    }

    .mobileHeaderClose > div:nth-child(2) {
        position: absolute;
        top: 47%;
        left: -20%;
        width: 25px;
        height: 1px;
        background-color: #FFFFFF;
        transform: rotate(-45deg);
    }

    .wrap {
        width: 100vw;
        height: 84px;
        position: absolute;
        top: 0;
        z-index: 999;
    }
      
    .topContainer {
        height: 48px;
        background-color: #222222;
        display: flex;
        padding: 0 20px;
        align-items: center;
        justify-content: flex-end;
        color: #FFFFFF;
        font-size: 14px;
        display: none;
    }
    
    .mobileTopContainer {
        margin-top: auto;
        display: flex;
        flex-direction: column;
        gap: 10px;
        color: #FFFFFF;
        font-size: 12px;
    }

    .bottomContainer {
        width: 1300px;
        margin: 0 auto;
        display: none;
    }

    .bottomInnerContainer {
        height: 100px;
        background-color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .mobileBottomContainer {
        margin-top: 72px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        color: #FFFFFF;
        font-size: 18px;
    }
    
    .topItem {
    padding: 0 20px;
    cursor: pointer;
    height: inherit;
    display: flex;
    align-items: center;
    }

    .topItem span {
        margin-right: 6px;
    }

    .bold {
        font-weight: 900;
    }

    .bottomContentHover {
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
    }

    .close {
        transform: rotate(180deg);
    }

    .bottomContentHover > img {
        margin-right: 16px;
        cursor: pointer;
        transition: 0.3s ease-out;
    }

    .bottomItem {
        font-weight: 500;
        position: relative;
    }

    .bottomItem > a {
        text-decoration: none;
        height: inherit;
        padding: 0 20px;
        display: flex;
        align-items: center;
        color: #FFFFFF;
        font-size: 18px;
        display: block;
        color: #FFFFFF;
        font-size: 18px;
    }

    .bottomItem > span {
        text-decoration: none;
        height: inherit;
        padding: 0 20px;
        display: flex;
        align-items: center;
        color: #FFFFFF;
        font-size: 18px;
        cursor: default;
    }


    /* .bottomItem .active > .bottomContentHover > span {
        color: #DC140A !important;
    } */

    .bottomItem:hover > span,
    .bottomItem:hover > a span {
        color: #DC140A;
    }

    .bottomContentHover:hover > span,
    .bottomContentHover:hover > a span {
        cursor: default;
    }

    .bottomItemSub {
        transition: 0.3s ease-out;
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 6px 30px;
    }

    .hide {
        opacity: 0;
    }

    .hoverItem {
        font-size: 16px;
        font-weight: 500;
    }

    .hoverItem > a {
        text-decoration: none;
        color: #A2A2A2;
        display: block;
        padding: 6px 0 0;
        font-weight: 400;
    }

    .hamLogo {
        width: 68.33px;
        margin: 47px 0 20px 20px;
    }

    .doubleContent {
        color: #A2A2A2 !important;
    }

    .doubleContent > a > span {
        display: block;
        font-size: 12px;
        margin-top: 4px;
        text-decoration: none;
        color: #A2A2A2 !important;
    }
}

@media only screen and (min-width: 1300px) {
    .wrap {
        width: 100%;
        height: 148px;
        position: fixed;
        z-index: 999;
    }
    
    .topContainer {
        height: 48px;
        background-color: #222222;
        display: flex;
        padding: 0 20px;
        align-items: center;
        justify-content: flex-end;
        color: #FFFFFF;
        font-size: 14px;
    }
    
    .bottomContainer {
        width: 100%;
        background-color: #FFFFFF;
    }

    .bottomInnerContainer {
        margin: 0 auto;
        width: 1300px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: solid 1px #E6E6E6;
    }
    
    .topItem {
    padding: 0 10px;
    cursor: pointer;
    height: inherit;
    display: flex;
    align-items: center;
    }

    .topItem span {
        margin-right: 6px;
    }

    .bold {
        font-weight: 900;
    }

    .bottomItem {
        height: inherit;
        cursor: pointer;
        font-weight: 500;
        position: relative;
    }

    .bottomItem > a {
        text-decoration: none;
        height: inherit;
        padding: 0 32px;
        display: flex;
        align-items: center;
        color: #222222;
        font-size: 20px;
    }

    .bottomItem > a img {
        width: 131px;
    }

    .bottomItem > a span {
        color: #222222;
        font-size: 20px;
        display: block;
    }

    .bottomItem > span {
        text-decoration: none;
        height: inherit;
        padding: 0 32px;
        display: flex;
        align-items: center;
        /* color: #222222; */
        font-size: 20px;
        cursor: default;
    }

    .bottomItem:hover > span,
    .bottomItem:hover > a span {
        color: #DC140A;
    }

    .bottomItem:hover > .bottomItemSub,
    .bottomItem:hover > .bottomItemSub3 {
        top: 100%;
    }

    .bottomItemSub {
        width: 162px;
        position: absolute;
        top: -1px;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
        transition: 0.3s ease-out;
    }

    .bottomItemSub3 {
        width: 162px;
        position: absolute;
        top: -99px;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
        transition: 0.3s ease-out;
    }

    .hoverItem {
        background-color: #FAFAFA;
        font-size: 18px;
        font-weight: 500;
        text-align: center;
    }

    .hoverItem > a {
        text-decoration: none;
        color: #222222;
        display: block;
        padding: 14px 0;
    }

    .hoverItem:first-child {
        border-top: 1px solid #DC140A;
    }

    .hoverItem:nth-child(n+2) {
        border-top: 1px solid #E8E8E8;
    }
    .mobileHeaderWrap {
        display: none;
    }

    .doubleContent {
        padding: 14px 0;
        color: #222222 !important;
    }

    .doubleContent > a > span {
        display: block;
        font-size: 14px;
        margin-top: 4px;
        text-decoration: none;
        color: #222222 !important;
    }
}