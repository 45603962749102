@media only screen and (max-width: 1299px) {
    .wrap {
        width: 100vw;
        height: 267px;
        background-color: #F4F4F4;
        padding: 33px 0 30px;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        gap: 15px;
    }

    .topContainer{
        width: 100vw;
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        height: 60px;
        position: relative;
    }

    .bottomContainer {
        width: 100vw;
        display: flex;
        padding: 0 20px;
        flex-direction: column;
        justify-content: space-between;
        gap: 52px;
    }

    .topLogo img {
        width: 80px;
        display: block;
    }

    .topLink {
        display: flex;
        align-items: center;
        height: inherit;
        display: none;
    }

    .topLinkItem {
        height: inherit;
        position: relative;
    }

    .topLinkItem > a,
    .topLinkItem > span {
        text-decoration: none;
        color: #222222;
        font-size: 20px;
        font-weight: 500;
        padding: 0 32px;
        display: flex;
        align-items: flex-end;
        height: inherit;
    }

    .hoverItem {
        margin-top: 39px;
        text-align: center;
        display: flex;
        flex-flow: column;
        gap: 11px;
    }

    .hoverItem > a > span {
        display: block;
        color: #222222;
        font-size: 18px;
    }

    .topHr {
        width: 1300px;
        height: 0;
        border-top: 1px solid #222222;
        opacity: 0.16;
        position: absolute;
        top: 78px;
        margin: 0;
        display: none;
    }

    .bottomSelect {
        position: relative;
        margin-top: auto;
    }

    .bottomInfo > span {
        line-height: 16px;
        font-size: 10px;
        color: #5A5A5A;
        font-weight: 500;
    }

    .postContainer {
        width: 20px;
        height: 20px;
        position: absolute;
        display: flex;
        gap: 10px;
        left: 0;
        top: -36px;
        z-index: 1;
    }

    .postContainer > img {
        width: 24px;
        height: 24px;
        cursor: pointer;
    }

    .selectBox {
        width: 152px;
        height: 32px;
        color: #FFFFFF;
        background-color: #222222;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 14px 0 10px;
        cursor: pointer;
        transition: 0.3s ease-out;
        font-size: 10px;
    }

    .selectBox > img {
        width: 6px;
        transition: 0.3s ease-out;
    }

    .show{
        rotate: 180deg;
    }

    .selectBoxHr {
        margin: 0;
        border: none;
        width: 132px;
        border-top: 1px solid #FFFFFF;
        opacity: 0.2;
    }

    .selectBoxWrap {
        position: absolute;
        top: -83px;
        overflow: hidden;
    }

    .selectBoxContent {
        background-color: #222222;
        color: #FFFFFF;
        display: flex;
        flex-flow: column;
        padding: 10px 10px 0;
        gap: 12px;
        top: 0;
        width: 152px;
        transition: 0.3s ease-out;
        transform: translateY(83px);
        font-size: 10px;
        position: relative;
        z-index: 2;
    }

    .selectBoxContent.open {
        transform: translateY(0);
    }

    .selectBoxContent > span {
        transition: 0.3s ease-out;
        opacity: 0;
        cursor: pointer;
    }

    .selectBoxContent.open > span {
        opacity: 1;
    }
}
@media only screen and (min-width: 1300px) {
    .wrap {
        width: 100%;
        height: 450px;
        background-color: #F4F4F4;
        padding: 68px 0 60px;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
    }

    .topContainer{
        width: 1300px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        height: 60px;
        position: relative;
    }

    .bottomContainer {
        width: 1300px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
    }

    .topLogo {
        padding-left: 32px;
        align-items: center;
        display: flex;
    }

    .topLogo img {
        width: 130px;
        display: block;
    }

    .topLink {
        display: flex;
        align-items: center;
        height: inherit;
    }

    .topLinkItem {
        height: inherit;
        position: relative;
    }

    .topLinkItem > a,
    .topLinkItem > span {
        text-decoration: none;
        color: #222222;
        font-size: 20px;
        font-weight: 500;
        padding: 0 32px;
        display: flex;
        align-items: center;
        height: inherit;
    }

    .hoverItem {
        margin-top: 39px;
        text-align: center;
        display: flex;
        flex-flow: column;
        gap: 11px;
    }

    .hoverItem > a > span {
        display: block;
        color: #222222;
        font-size: 18px;
    }

    .topHr {
        width: 1300px;
        height: 0;
        border-top: 1px solid #222222;
        opacity: 0.16;
        position: absolute;
        top: 78px;
        margin: 0;
    }

    .bottomSelect {
        position: relative;
        height: 45px;
        margin-top: auto;
    }

    .bottomInfo > span {
        line-height: 22px;
        font-size: 14px;
        color: #5A5A5A;
        font-weight: 500;
    }

    .postContainer {
        width: 32px;
        height: 32px;
        position: absolute;
        display: flex;
        gap: 10px;
        top: 50%;
        transform: translateY(-50%);
        left: -188px;
    }

    .postContainer > img {
        width: 32px;
        height: 32px;
        cursor: pointer;
    }

    .selectBox {
        width: 200px;
        height: 45px;
        color: #FFFFFF;
        background-color: #222222;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 16px;
        cursor: pointer;
        transition: 0.3s ease-out;
    }

    .selectBox > img {
        width: 12px;
        transition: 0.3s ease-out;
    }

    .show{
        rotate: 180deg;
    }

    .selectBoxHr {
        margin: 0;
        border: none;
        width: 168px;
        border-top: 1px solid #FFFFFF;
        opacity: 0.2;
    }

    .selectBoxWrap {
        position: absolute;
        top: -106px;
        overflow: hidden;
    }

    .selectBoxContent {
        background-color: #222222;
        color: #FFFFFF;
        display: flex;
        flex-flow: column;
        padding: 12px 16px 0 16px;
        gap: 12px;
        top: 0;
        width: 200px;
        transition: 0.3s ease-out;
        transform: translateY(108px);
    }

    .selectBoxContent.open {
        transform: translateY(0);
    }

    .selectBoxContent > span {
        transition: 0.3s ease-out;
        opacity: 0;
        cursor: pointer;
    }

    .selectBoxContent.open > span {
        opacity: 1;
    }

    .doubleContent {
        color: #222222;
        font-size: 18px;
    }

    .doubleContent > a > span {
        display: block;
        font-size: 14px;
        margin-top: 4px;
        text-decoration: none;
        color: #222222;
    }
}