@media all and (min-width: 1300px) {
    .wrap {
        position: absolute;
        width: 100vw;
        height: 100%;
        z-index: 99997;
    }

    .backWrap {
        width: 100vw;
        height: 100%;
        z-index: 99998;
        top: 0;
        left: 0;
    }

    .popupContainer {
        width: 500px;
        height: 491px;
        position: absolute;
        top: 30px;
        left: calc(3% + 500px);
        z-index: 99999;
    }

    .popupHeader {
        width: 100%;
        height: 56px;
        background-color: #e50019;
        position: relative;
    }

    .popupHeader > img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 16px;
        cursor: pointer;
    }

    .popupBody {
        height: 100%;
        overflow: auto;
        padding: 20px;
        background-color: #fff;
    }

    .popupBody .popupCnt {
        text-align: center;
        border-top: 1px solid #434343;
        border-bottom: 1px solid #434343;
        padding: 20px;
    }

    .popupCnt > h2 {
        font-size: 20px;
        font-weight: 700;
    }

    .popupCnt > img {
        width: 100%;
    }

    .popupCnt > p {
        margin-top: 60px;
        font-size: 16px;
        line-height: 1.5;
        font-weight: 400;
    }

    .popupCnt > div p{
        font-size: 14px;
        line-height: 1.5;
        font-weight: 400;
    }

    .popupCnt > div {
        width: 60%;
        margin: 40px auto;
    }

    .popupCnt > div address{
        font-style: normal;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;
    }

    .popupFooter {
        width: 100%;
        height: 40px;
        background-color: #F4F4F4;
    }

    .popupFooter > span {
        color: #6F6F6F;
        font-size: 14px;
        letter-spacing: -0.14px;
        display: block;
        text-align: right;
        padding-right: 24px;
        text-decoration: underline;
        cursor: pointer;
        line-height: 40px;
    }
}

@media all and (max-width: 1299px) {
    .wrap {
        position: absolute;
        width: 100vw;
        height: 100%;
        z-index: 99997;
    }

    .backWrap {
        width: 100vw;
        height: 100%;
        z-index: 99998;
        top: 0;
        left: 0;
    }

    .popupContainer {
        width: 320px;
        height: 272px;
        position: absolute;
        top: calc(320px + 4%);
        left: calc(100vw / 2);
        transform: translateX(-50%);
        z-index: 99999;
    }

    .popupHeader {
        width: 100%;
        height: 32px;
        background-color: #e50019;
        position: relative;
    }

    .popupHeader > img {
        width: 15px;
        height: 15px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 12px;
        cursor: pointer;
    }

    .popupBody {
        background-color: #fff;
        height: 100%;
        overflow: auto;
    }

    .popupBody .popupCnt {
        text-align: center;
        padding: 10px;
    }

    .popupCnt > img {
        width: 100%;
    }

    .popupCnt > h2 {
        font-size: 16px;
        font-weight: 700;
    }

    .popupCnt > p {
        padding: 0 20px;
        margin-top: 30px;
        font-size: 12px;
    }

    .popupCnt > div p{
        font-size: 10px;
        line-height: 1.6;
    }

    .popupCnt > div {
        width: 60%;
        margin: 15px auto;
    }

    .popupCnt > div address{
        font-style: normal;
        font-size: 10px;
        font-weight: 400;
    }

    .popupFooter {
        width: 100%;
        height: 24px;
        background-color: #F4F4F4;
    }

    .popupFooter > span {
        color: #6F6F6F;
        font-size: 9px;
        letter-spacing: -0.09px;
        display: block;
        text-align: right;
        padding-right: 12px;
        text-decoration: underline;
        cursor: pointer;
        line-height: 24px;
    }
}