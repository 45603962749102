@keyframes imgBack {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes imgLogo {
    0% {
        transform: translateY(100%);
    }
    100% {
        transfrom: translateY(0);
    }
}

@keyframes content {
    0% {
        transform: translateY(40px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes arrow {
    0% {
        transform: translateY(-40px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

#modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 10000;
}

@media only screen and (max-width: 1299px) {
    .wrap {
        width: 100vw;
    }
    
    .imgBack {
        position: relative;
        padding-top: 84px;
    }
    
    .imgBack > img {
        width: 100vw;
        display: block;
    }
    
    .contentContainer {
        font-size: 18px;
        line-height: 17px;
        color: #222222;
        font-weight: 900;
        text-align: left;
        display: flex;
        justify-content: space-between;
        position: absolute;
        bottom: -12px;
        padding: 0 20px;
        width: 100vw;
    }
    
    .contentContainer span {
        display: block;
    }
    
    .arrow {
        width: 19.5px;
        height: 32.72px;
        background-color: #DC140A;
        border-radius: 16px;
        position: relative;
        margin-top: auto;
        animation: arrow 0.6s;
        animation-delay: 0.6s;
        animation-fill-mode: both;
        top: -28px;
    }
    
    .arrow img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 7.5px
    }
    
    .content {
        animation: content 0.6s ease-out;
        animation-fill-mode: both;
    }
    
    .explainContainer {
        overflow-x: hidden;
        margin: 0 auto;
    }
    
    .explain {
        margin-top: 64.77px;
        text-align: center;
        display: flex;
        flex-direction: column;
    }
    
    .explain:first-child {
        padding-bottom: 55.23px;
    }
    
    #explainLogo {
        opacity: 1;
        transition: 0.6s ease-out;
    }
    
    .redDot {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #FF4545;
        margin-top: 8px;
        flex-shrink: 0;
    }
    
    .redDot2 {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #FF4545;
    }
    
    .explainItem img {
        display: block;
        width: 150px;
        margin: 0 auto;
    }
    
    .explainItem:nth-child(2) {
        margin: 40.77px 0 56px;
        display: flex;
        justify-content: center;
        gap: 16px;
        text-align: left;
        padding: 0 36px;
    }
    
    .explainItem:nth-child(2) span {
        display: block;
        font-size: 16px;
        font-weight: 500;
        color: #434343;
        line-height: 26px;
        word-break: keep-all;
    }
    
    .explainItem:nth-child(3) a {
        display: inline-block;
    }
    
    .explainButton {
        width: 82px;
        height: 27px;
        background-color: #8BD7FF;
        border: none;
        border-radius: 24px;
        display: flex;
        align-items: center;
        color: #FFFFFF;
        font-weight: 500;
        font-size: 16px;
        padding: 0 16px;
        justify-content: space-between;
        margin: 0 auto;
        cursor: pointer;
    }
    
    .explainButton > img {
        width: 6px;
    }

    .explain:nth-child(2) {
        background-color: #FAFAFA;
        padding: 80px 0;
        height: 735px;
    }
    .explainItem2:nth-child(1) {
        margin-bottom: 80px;
        color: #222222;
        display: flex;
        justify-content: center;
        gap: 6px;
        align-items: center;
        font-size: 24px;
    }
    
    .explainItem2:nth-child(2) {
        display: flex;
        gap: 31px;
        justify-content: center;
    }

    .explainItem2:nth-child(1) > span {
        font-family: Montserrat, sans-serif;
    }
    
    .spectrumContainer {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 72px;
    }
    
    .spectrumItem {
        position: relative;
        height: 240px;
        transform: translateY(-24px);
    }

    .spectrumItem:nth-child(2) {
        transform: translateY(-82px);
    }

    .spectrumItem:nth-child(3) {
        height: 206px;
        overflow: hidden;
        transform: translateY(-140px);
    }

    .spectrumIcon {
        position: absolute;
        left: 30px;
        top: 13px;
        left: 50%;
        transform: translateX(-50%);
        width: 44px;
        z-index: 1;
    }
    
    .spectrumItem img:nth-child(2) {
        height: 72px;
        position: absolute;
        transform-origin: 36px;
        transform: rotate(90deg);
        left: 0;
    }

    .spectrumContentContainer {
        display: flex;
        flex-direction: column;
        text-align: left;
        gap: 50px;
    }
    
    .spectrumContentItem {
        width: 217px;
        display: flex;
        flex-flow: column;
        gap: 12px;
    }
    
    .contentItemTheme {
        font-size: 16px;
        font-weight: bold;
        color: #222222;
        padding-left: 9px;
    }
    
    .contentItemcontent {
        font-size: 12px;
        color: #434343;
        text-indent: -8px;
        padding-left: 8px;
        line-height: 20px;
    }
    
    .contentItemcontent > span {
        display: block;
    }
    
    .explain:nth-child(3) {
        margin-top: 120px;
    }

    .explainItem3:nth-child(2) {
        width: 1300px;
        margin: 120px auto 0 auto;
        display: flex;
        flex-flow: row;
        gap: 50px;
        position: relative;
    }
    
    .businessItem {
        width: 400px;
        display: flex;
        flex-flow: column;
        gap: 40px;
        text-align: left;
        flex-shrink: 0;
    }
    
    .businessitemLine {
        position: absolute;
        width: 1610px;
        height: 1px;
        background-image: linear-gradient(to right, #DC140A 10%, rgba(255, 255, 255, 0) 0%);
        background-position: top;
        background-size: 11px 1px;
        background-repeat: repeat-x;
        top: 80px;
        z-index: -10;
    }
    
    .businessItemImgContainer {
        background-color: #DC140A;
        width: 160px;
        height: 160px;
        border-radius: 50%;
        border: none;
        position: relative;
    }
    
    .businessItemImgContainer img {
        width: 100px;
        height: 100px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    
    .businessItemThemeContainer {
        font-size: 40px;
        color: #222222;
        font-weight: 600;
        line-height: 36px;
    }
    
    .businessItemContentContainer {
        color: #434343;
        font-size: 26px;
        line-height: 44px;
        word-break: keep-all;
    }
    
    .businessItemContentContainer span {
        display: block;
        text-indent: -18px;
        padding-left: 18px;
    }

    .explainItem4 {
        width: 240px;
        height: 65px;
        margin: 0 auto;
    }
    
    .explainItem4 > img {
        width: 150px;
    }

    .explainItem4:nth-child(2) {
        margin-top: 21.75px;
        margin-bottom: 120px;
    }
    
    .explainItem4Info {
        color: #222222;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
    }
}

@media only screen and (min-width: 1300px) {
    .wrap {
        width: 100%;
    }

    .imgBack {
        width: 100%;
        position: relative;
    }

    .imgBack > img {
        width: inherit;
    }

    .contentContainer {
        font-size: 80px;
        line-height: 80px;
        color: #222222;
        font-weight: bold;
        width: 1300px;
        text-align: left;
        display: flex;
        justify-content: space-between;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -50px;
    }

    .contentContainer span {
        width: 614px;
        display: block;
    }

    .arrow {
        width: 32px;
        height: 64px;
        background-color: #DC140A;
        border-radius: 16px;
        position: relative;
        margin-top: auto;
        animation: arrow 0.6s;
        animation-delay: 0.6s;
        animation-fill-mode: both;
        top: -100px;
    }

    .arrow img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .content {
        animation: content 0.6s ease-out;
        animation-fill-mode: both;
    }

    .explainContainer {
        overflow-x: hidden;
        margin: 0 auto;
    }

    .explain {
        margin-top: 240px;
        text-align: center;
    }

    .explainItem.hover {
        height: 200px;
    }

    .explainItem.hover img {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }

    #explainLogo {
        opacity: 1;
    }

    .redDot {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #FF4545;
        margin-top: 13px;
    }

    .redDot2 {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #FF4545;
    }

    .explainItem img {
        display: block;
        width: 450px;
        margin: 0 auto;
    }

    .explainItem:nth-child(2) {
        margin: 80px 0 90px;
        display: flex;
        justify-content: center;
        gap: 36px;
        text-align: left;
    }

    .explainItem:nth-child(2) span {
        display: block;
        width: 1180px;
        font-size: 32px;
        font-weight: 500;
        color: #434343;
        line-height: 44px;
    }

    .explainItem:nth-child(3) a {
        display: inline-block;
    }

    .explainButton {
        width: 147px;
        height: 48px;
        background-color: #8BD7FF;
        border: none;
        border-radius: 24px;
        display: flex;
        align-items: center;
        color: #FFFFFF;
        font-weight: bold;
        font-size: 26px;
        padding: 0 32px;
        justify-content: space-between;
        margin: 0 auto;
        cursor: pointer;
    }

    .explain:nth-child(2) {
        background-color: #FAFAFA;
        padding: 200px 0;
    }
    .explainItem2:nth-child(1) {
        margin-bottom: 80px;
        color: #222222;
        display: flex;
        justify-content: center;
        gap: 16px;
        align-items: center;
        font-size: 56px;
    }

    .explainItem2:nth-child(1) > span {
        font-family: Montserrat, sans-serif;
    }

    .spectrumContainer {
        margin-bottom: 40px;
        position: relative;
        height: 160px;
        padding-left: calc((100vw - 1300px) / 2);
        display: flex;
    }

    .spectrumItem {
        position: relative;
    }

    .spectrumIcon {
        position: absolute;
        left: 30px;
        top: 50%;
        transform: translateY(-50%);
    }

    .spectrumItem:nth-child(n+2) {
        margin-left: -190px;
    }

    .spectrumContentContainer {
        width: 1300px;
        margin: 0 auto;
        display: flex;
        text-align: left;
        gap: 50px;
    }

    .spectrumContentItem {
        width: 400px;
        display: flex;
        flex-flow: column;
        gap: 40px;
    }

    .contentItemTheme {
        font-size: 40px;
        font-weight: bold;
        color: #222222;
    }

    .contentItemcontent {
        font-size: 26px;
        color: #434343;
        text-indent: -18px;
        padding-left: 18px;
        line-height: 44px;
    }

    .contentItemcontent > span {
        display: block;
    }

    .explainItem3:nth-child(2) {
        width: 1300px;
        margin: 120px auto 0 auto;
        display: flex;
        flex-flow: row;
        gap: 50px;
        position: relative;
    }

    .businessItem {
        width: 400px;
        display: flex;
        flex-flow: column;
        gap: 40px;
        text-align: left;
        flex-shrink: 0;
    }

    .businessitemLine {
        position: absolute;
        width: 1610px;
        height: 1px;
        background-image: linear-gradient(to right, #DC140A 10%, rgba(255, 255, 255, 0) 0%);
        background-position: top;
        background-size: 11px 1px;
        background-repeat: repeat-x;
        top: 80px;
        z-index: -10;
    }

    .businessItemImgContainer {
        background-color: #DC140A;
        width: 160px;
        height: 160px;
        border-radius: 50%;
        border: none;
        position: relative;
    }

    .businessItemImgContainer img {
        width: 100px;
        height: 100px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .businessItemThemeContainer {
        font-size: 40px;
        color: #222222;
        font-weight: 600;
        line-height: 36px;
    }

    .businessItemContentContainer {
        color: #434343;
        font-size: 26px;
        line-height: 44px;
        word-break: keep-all;
    }

    .businessItemContentContainer span {
        display: block;
        text-indent: -18px;
        padding-left: 18px;
    }

    .explainItem4 {
        width: 448px;
        margin: 0 auto;
    }

    /*.explainItem4 > img {*/
    /*    width: 157px;*/
    /*}*/

    .explainItem4:nth-child(2) {
        margin-top: 80px;
        margin-bottom: 240px;
    }

    .explainItem4Info {
        color: #222222;
        font-size: 32px;
        font-weight: 600;
        line-height: 40px;
    }
}