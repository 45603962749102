@media only screen and (max-width: 1299px) {
    .wrap {
        width: 100vw;
        padding: 84px 0 88px;
        min-height: calc(100vh - 231px);
    }

    .container {
        width: 100vw;
        margin: 0 auto;
    }

    .categoryContainer {
        color: #222222;
        font-size: 32px;
        font-weight: bold;
        text-align: center;
        animation: divUp 0.6s ease-in-out;
        animation-fill-mode: both;
    }

    .commentContainer {
        text-align: center;
        display: flex;
        flex-flow: column;
        gap: 24px;
        margin: 62px 0 40px;
        animation: divUp 0.6s ease-in-out;
        animation-delay: 0.6s;
        animation-fill-mode: both;
        /* letter-spacing: -0.7px; */
    }

    .commentTheme {
        color: #222222;
        font-size: 24px;
        font-weight: 600;
    }

    .commentContent {
        color: #434343;
        font-size: 14px;
        line-height: 24px;
        padding: 0 20px;
        word-break: keep-all;
        text-align: left;
    }

    .companyContainer {
        display: flex;
        gap: 84px;
        padding: 0 20px;
        overflow-x: scroll;
        overflow-y: hidden;
    }

    .companyContainer::-webkit-scrollbar {
        display: block !important;
        height: 2px;
    }

    .companyContainer::-webkit-scrollbar-button:start:decrement,
    .companyContainer::-webkit-scrollbar-button:end:decrement {
        display: block;
        background-color: transparent;
        width: 20px;
    }
    .companyContainer::-webkit-scrollbar-thumb {
        background-color: #A2A2A2;
        height: 2px;
    }

    .companyContainer::-webkit-scrollbar-track {
        background-color: #DDDDDD;
    }

    .companyItem {
        min-width: 320px;
        display: flex;
        flex-flow: column;
        animation: divUp 0.6s ease-in-out;
        animation-fill-mode: both;
        margin-bottom: 40.27px;
    }

    .companyItemImg {
        position: relative;
        width: 200px;
        height: 200px;
        margin: 0 auto;
    }

    .companyItem:nth-child(1) { animation-delay: 1.2s }
    .companyItem:nth-child(2) { animation-delay: 1.3s }
    .companyItem:nth-child(3) { animation-delay: 1.4s }
    .companyItem:nth-child(4) { animation-delay: 1.5s }
    .companyItem:nth-child(5) { animation-delay: 1.6s }
    .companyItem:nth-child(6) { animation-delay: 1.7s }

    .companyItemImg > img {
        width: 200px;
    }

    #overlabB {
        opacity: 1;
        transition: 0.6s ease-out;
    }

    #overlabB:hover {
        opacity: 1;
    }

    #overlabR {
        opacity: 0;
        transition: 0.6s ease-out;
    }

    #overlabR:hover {
        opacity: 1;
    }

    .companyItemTheme {
        color: #222222;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        margin: 40.73px 0 12px;
    }

    .companyItemContent {
        margin: 0 auto;
        color: #434343;
        font-size: 14px;
        line-height: 24px;
        word-break: keep-all;
        text-align: center;
    }

    @keyframes divUp {
        0% {
            opacity: 0;
            transform: translateY(40px);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }

    .productionContainer {
        animation: divUp 0.6s ease-out;
        animation-fill-mode: both;
        animation-delay: 1.2s;
        padding: 0 20px;
    }

    .productionContainer:nth-child(n+2) {
        margin-top: 60px;
    }

    .productionTheme {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 12px;
    }

    .productionTheme > span {
        color: #222222;
        font-size: 20px;
        font-weight: bold;
        flex-shrink: 0;
    }

    .productionTheme > hr {
        width: 100%;
        border: none;
        border-top: 1px solid #DC140A;
        height: 0;
        margin: 0;
    }
    .productionItemContainer {
        display: flex;
        flex-wrap: wrap;
        margin-top: 24px;
        gap: 16px;
    }

    .productionItem {
        display: flex;
        width: 100%;
        height: 228px;
    }

    .productionItem:nth-child(n) {
        animation: divUp 0.6s ease-in-out;
        animation-fill-mode: both;
    }

    .productionItemImg {
        width: 152px;
        height: 228px;
    }

    .productionItemImg > img {
        width: inherit;
        height: inherit;
    }

    .productionItemInfoContainer {
        width: calc(100% - 152px);
        height: 228px;
        background-color: #FAFAFA;
        overflow: hidden;
    }

    .productionItemInfoWrap {
        width: calc(100% - 32px);
        height: 196px;
        overflow: auto;
        margin: 16px;
    }

    .productionItemInfoWrap::-webkit-scrollbar {
        width: 4px;
    }

    .productionItemInfoWrap::-webkit-scrollbar-thumb {
        background-color: #EAEAEA;
        border-radius: 20px;
    }

    .productionItemInfo {
        
    }

    .productionItemInfo hr {
        width: 100%;
        height: 1px;
        background-color: #DDDDDD;
        border: none;
        margin: 10px 0;
    }

    .productionItemTheme {
        color: #434343;
        font-size: 15px;
        font-weight: 600;
        white-space: pre-wrap;
        word-break: break-all;
    }

    .prodictionItemList > span {
        display: block;
    }

    .listTheme {
        color: #A2A2A2;
        font-size: 10px;
        font-weight: 500;
        margin-bottom: 2px;
    }

    .listContent {
        color: #434343;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        margin-bottom: 8px;
        white-space: pre-wrap;
        word-break: break-all;
    }

    .listContent:last-child {
        margin-bottom: 0;
    }

    .productionNodataContainer {
        margin-bottom: 160px;
        animation: divUp 0.6s ease-out;
        animation-fill-mode: both;
        animation-delay: 1.2s;
    }

    .productionNoData {
        color: #222222;
        font-size: 16px;
        font-weight: 600;
        display: block;
        text-align: center;
        margin-top: 60px;
    }

    .producerItem {
        width: 100%;
        height: 136px;
        display: flex;
        gap: 24px;
        padding: 20px 0;
        flex-direction: column;
        transition: 0.3s ease-out;
        border-bottom: #F4F4F4 1px solid;
        position: relative;
    }

    .producerItem:nth-child(1) {
        margin-top: -40px;
    }

    .producerItemInfo {
        display: flex;
        gap: 16px;
    }

    .producerItemInfo img {
        width: 96px;
        height: 96px;
    }

    .producerItemName {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .producerItemName > span:nth-child(1) {
        color: #222222;
        font-weight: 500;
        font-size: 12px;
    }

    .producerItemName > span:nth-child(2) {
        color: #222222;
        font-weight: 600;
        font-size: 18px;
    }

    .producerItemWork {
        color: #222222;
        font-size: 16px;
        font-weight: 500;
        display: flex;
        gap: 8px;
        flex-direction: column;
        width: 100%;
        position: relative;
        overflow-y: hidden;
    }

    .listArrow {
        position: absolute;
        width: 16px;
        height: 8px;
        right: 0;
        bottom: 20px;
        cursor: pointer;
    }

    .workItem > span {
        color: #434343;
        font-size: 12px;
        font-weight: 400;
        display: block;
        line-height: 20px;
    }

    .auditionForm {
        width: calc(100vw - 40px);
        margin: 0 auto;
        border-top: 2px solid #DC140A;
        animation: divUp 0.6s ease-in-out;
        animation-fill-mode: both;
        animation-delay: 0.6s;
    }

    .auditionItem {
        display: flex;
        flex-direction: column;
    }

    .auditionItem:nth-child(n+2) {
        border-bottom: 2px solid rgba(43, 43, 43, 0.2);
        flex-direction: row;
    }

    .auditionItem:last-child {
        border: none;
    }

    .auditionItemDiv {
        border-bottom: 2px solid rgba(43, 43, 43, 0.2);
        display: flex;
        align-items: center;
        gap: 32px;
    }

    .auditionTheme {
        font-size: 16px;
        font-weight: bold;
        color: #222222;
        width: 100px;
        padding: 24px 0 24px 8px;
        flex-shrink: 0;
    }

    .auditionContent {
        font-size: 16px;
        font-weight: 500;
        min-width: 208px;
        color: #222222;
        padding: 24px 0 24px 10px;
        display: flex;
        flex-flow: column;
        gap: 19px;
    }

    .auditionItem:nth-child(3) .auditionContent {
        flex-shrink: 0;
        flex-wrap: wrap;
        gap: 24px;
    }

    .auditionContentSpace {
        display: flex;
        gap: 20px;
    }

    .auditionContentSpace > span:nth-child(1) {
        width: 14px;
    }

    .auditionContentSpace > span:nth-child(2) {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .auditionContentEx {
        font-size: 12px;
        color: #434343;
    }

    .auditionContentFlex {
        display: flex;
        gap: 10px;
        flex-flow: column;
        min-width: 208px;
    }

    .flexContent2 > span {
        display: block;
        line-height: 40px;
        color: #434343;
        font-size: 12px;
        font-weight: 400;
        max-width: 208px;
    }

    .flexContent > span {
        display: block;
        line-height: 20px;
        color: #434343;
        font-size: 12px;
        font-weight: 400;
        max-width: 208px;
        word-break: keep-all;
        text-indent: -8px;
        padding-left: 8px;
    }

    .flexButton {
        margin-top: 24px;
        display: flex;
        gap: 10px 15px;
        flex-wrap: wrap;
    }

    .downloadButton {
        background-color: #DC140A;
        width: auto;
        height: 25px;
        border-radius: 13px;
        color: #FFFFFF;
        font-size: 12px;
        font-weight: bold;
        padding: 5px 16px;
        display: flex;
        justify-content: center;
        gap: 8px;
        align-items: center;
        cursor: pointer;
    }

    .downloadButton > img {
        width: 10px;
    }

    .companyScrollBarContainer {
        width: 100vw;
        padding: 0 20px;
        margin: 40.27px 0 88px;
        height: 8px;
        animation: divUp 0.6s ease-in-out;
        animation-delay: 0.6s;
        animation-fill-mode: both;
    }

    .companyScrollBar {
        position: relative;
        background-color: #DDDDDD;
        border: none;
        height: 1px;
    }

    .companyScrollBarShow {
        position: absolute;
        background-color: #A2A2A2;
        border: none;
        height: 2px;
        width: 0;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        transition: 0.3s ease-out;
    }
}

@media only screen and (min-width: 1300px) {
    .wrap {
        width: 100%;
        padding: 148px 0 240px;
    }

    .container {
        width: 1300px;
        margin: 0 auto;
    }

    .categoryContainer {
        color: #222222;
        font-size: 64px;
        font-weight: bold;
        text-align: center;
        animation: divUp 0.6s ease-in-out;
        animation-fill-mode: both;
    }

    .commentContainer {
        text-align: center;
        display: flex;
        flex-flow: column;
        gap: 24px;
        margin: 80px 0 160px;
        animation: divUp 0.6s ease-in-out;
        animation-delay: 0.6s;
        animation-fill-mode: both;
        /* letter-spacing: -0.7px; */
    }

    .commentTheme {
        color: #222222;
        font-size: 40px;
        font-weight: 600;
    }

    .commentContent {
        color: #434343;
        font-size: 26px;
        line-height: 44px;
        width: 1000px;
        margin: 0 auto;
        word-break: keep-all;
        text-align: left;
    }

    
    .productionComment {
        width: 900px;
    }

    .companyContainer {
        display: flex;
        gap: 160px 20px;
        flex-wrap: wrap;
    }

    .companyItem {
        height: 855px;
        display: flex;
        flex-flow: column;
        animation: divUp 0.6s ease-in-out;
        animation-fill-mode: both;
    }

    .companyItem:nth-child(3n-2) {
        justify-content: flex-start;
    }

    .companyItem:nth-child(3n-1) {
        justify-content: center;
    }

    .companyItem:nth-child(3n) {
        justify-content: flex-end;
    }

    .companyItemImg {
        position: relative;
        width: 420px;
        height: 280px;
    }

    .companyItem:nth-child(1) { animation-delay: 1.2s }
    .companyItem:nth-child(2) { animation-delay: 1.3s }
    .companyItem:nth-child(3) { animation-delay: 1.4s }
    .companyItem:nth-child(4) { animation-delay: 1.5s }
    .companyItem:nth-child(5) { animation-delay: 1.6s }
    .companyItem:nth-child(6) { animation-delay: 1.7s }

    .companyItemImg > img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    #overlabB {
        opacity: 1;
        transition: 0.6s ease-out;
    }

    #overlabB:hover {
        opacity: 1;
    }

    #overlabR {
        opacity: 0;
        transition: 0.6s ease-out;
    }

    #overlabR:hover {
        opacity: 1;
    }

    .companyItemTheme {
        color: #222222;
        font-size: 40px;
        font-weight: 600;
        text-align: center;
        margin: 65px 0 24px;
    }

    .companyItemContent {
        width: 400px;
        color: #434343;
        font-size: 26px;
        line-height: 44px;
        word-break: keep-all;
        text-align: center;
    }

    @keyframes divUp {
        0% {
            opacity: 0;
            transform: translateY(40px);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }

    .productionContainer {
        animation: divUp 0.6s ease-out;
        animation-fill-mode: both;
        animation-delay: 1.2s;
    }

    .productionContainer:nth-child(n+2) {
        margin-top: 160px;
    }

    .productionTheme {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 46px;
    }

    .productionTheme > span {
        color: #222222;
        font-size: 56px;
        font-weight: bold;
    }

    .productionTheme > hr {
        width: 1080px;
        border: none;
        border-top: 1px solid #DC140A;
        height: 0;
        margin: 0;
    }

    .productionItemContainer {
        display: flex;
        flex-wrap: wrap;
        margin-top: 48px;
        gap: 20px;
    }

    .productionItem {
        display: flex;
        width: 640px;
        height: 465px;
    }

    .productionItem:nth-child(n) {
        animation: divUp 0.6s ease-in-out;
        animation-fill-mode: both;
    }

    .productionItemImg {
        width: 310px;
        height: 465px;
    }

    .productionItemImg > img {
        width: inherit;
        height: inherit;
    }

    .productionItemInfoContainer {
        width: 330px;
        height: 465px;
        background-color: #FAFAFA;
        overflow: hidden;
    }

    .productionItemInfoWrap {
        width: 290px;
        height: 413px;
        overflow: auto;
        margin: 26px 20px;
    }

    .productionItemInfoWrap::-webkit-scrollbar {
        width: 8px;
    }

    .productionItemInfoWrap::-webkit-scrollbar-thumb {
        background-color: #EAEAEA;
        border-radius: 20px;
    }

    .productionItemInfo {
        
    }

    .productionItemInfo hr {
        width: 280px;
        height: 1px;
        background-color: #DDDDDD;
        border: none;
        margin: 24px 0;
    }

    .productionItemTheme {
        color: #434343;
        font-size: 32px;
        font-weight: 600;
        white-space: pre-wrap;
        word-break: break-all;
    }

    .prodictionItemList > span {
        display: block;
    }

    .listTheme {
        color: #A2A2A2;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 4px;
    }

    .listContent {
        color: #434343;
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        margin-bottom: 16px;
        white-space: pre-wrap;
        word-break: break-all;
    }

    .listContent:last-child {
        margin-bottom: 0;
    }

    .productionNodataContainer {
        margin-bottom: 160px;
        animation: divUp 0.6s ease-out;
        animation-fill-mode: both;
        animation-delay: 1.2s;
    }

    .productionNoData {
        color: #222222;
        font-size: 40px;
        font-weight: 600;
        display: block;
        text-align: center;
        margin-top: 120px;
    }

    .producerItem {
        width: 1300px;
        height: 248px;
        display: flex;
        gap: 20px;
        padding: 44px 0;
        transition: 0.3s ease-out;
        border-bottom: #F4F4F4 1px solid;
        position: relative;
    }

    .producerItemInfo {
        display: flex;
        gap: 60px;
    }

    .producerItemInfo img {
        width: 160px;
        height: 160px;
    }

    .producerItemName {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 420px;
    }

    .producerItemName > span:nth-child(1) {
        color: #222222;
        font-weight: 500;
        font-size: 30px;
    }

    .producerItemName > span:nth-child(2) {
        color: #222222;
        font-weight: 600;
        font-size: 40px;
    }

    .producerItemWork {
        color: #222222;
        font-size: 30px;
        font-weight: 500;
        display: flex;
        gap: 14px;
        flex-direction: column;
        width: 640px;
        position: relative;
        overflow-y: hidden;
    }

    .listArrow {
        position: absolute;
        width: 40px;
        height: 20px;
        right: 24px;
        bottom: 52px;
        cursor: pointer;
    }

    .workItem > span {
        color: #434343;
        font-size: 24px;
        font-weight: 400;
        display: block;
        line-height: 40px;
    }

    .auditionForm {
        width: 1300px;
        margin: 0 auto;
        border-top: 2px solid #DC140A;
        animation: divUp 0.6s ease-in-out;
        animation-fill-mode: both;
        animation-delay: 0.6s;
    }

    .auditionItem {
        display: flex;
        gap: 20px;
    }

    .auditionItem:nth-child(n+2) {
        border-bottom: 2px solid rgba(43, 43, 43, 0.2);
    }

    .auditionItem:last-child {
        border: none;
    }

    .auditionItemDiv {
        width: 640px;
        border-bottom: 2px solid rgba(43, 43, 43, 0.2);
        display: flex;
        align-items: center;
        gap: 20px;
    }

    .auditionTheme {
        font-size: 32px;
        font-weight: bold;
        color: #222222;
        width: 200px;
        padding: 60px 0 60px 32px;
    }

    .auditionContent {
        font-size: 32px;
        font-weight: 500;
        min-width: 420px;
        color: #222222;
        padding: 60px 0 60px 20px;
        display: flex;
        flex-flow: column;
        gap: 27px;
    }

    .auditionItem:nth-child(3) .auditionContent {
        max-width: 1080px;
        flex-flow: row;
        flex-shrink: 0;
        flex-wrap: wrap;
        gap: 60px 27px;
    }

    .auditionContentSpace {
        display: flex;
        gap: 32px;
    }

    .auditionContentSpace > span:nth-child(1) {
        width: 35px;
    }

    .auditionContentEx {
        font-size: 26px;
        color: #434343;
    }

    .auditionContentFlex {
        display: flex;
        gap: 24px;
        flex-flow: column;
        min-width: 420px;;
    }

    .flexContent2 > span {
        display: block;
        line-height: 40px;
        color: #434343;
        font-size: 26px;
        font-weight: 400;
        width: 420px;
    }

    .flexContent > span {
        display: block;
        line-height: 40px;
        color: #434343;
        font-size: 26px;
        font-weight: 400;
    }

    .flexButton {
        margin-top: 36px;
        display: flex;
        gap: 16px;
    }

    .downloadButton {
        background-color: #DC140A;
        width: auto;
        height: 43px;
        border-radius: 21px;
        color: #FFFFFF;
        font-size: 20px;
        font-weight: bold;
        padding: 0 32px;
        display: flex;
        justify-content: center;
        gap: 16px;
        align-items: center;
        cursor: pointer;
    }

    .companyScrollBarContainer {
        display: none;
    }
}