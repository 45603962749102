.wrap {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 1000;
    overflow: hidden;
}

.container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFFFFF;
}

.topContainer {
    background-color: #E63131;
    width: inherit;
    height: 64px;
    color: #FFFFFF;
    font-size: 28px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    padding: 0 24px 0 40px;
    align-items: center;
}

.topContainer img {
    width: 24px;
    cursor: pointer;
}

.middleContainer {
    padding: 0 40px;
}

.bottomContainer {
    background-color: #F4F4F4;
    width: inherit;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.actionButton {
    width: 198px;
    height: 64px;
    border-radius: 36px;
    background-color: #E63131;
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.actionButton2 {
    border-radius: 28px;
    background-color: #E63131;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.themeContainer {
    padding-top: 23px;
    display: flex;
    flex-direction: column;
    gap: 23px;
}

.middleTheme {
    color: #434343;
    font-size: 24px;
    font-weight: 600;
}

.middleLine {
    width: 100%;
    height: 1px;
    border: none;
    margin: 0;
    background-color: #DDDDDD;
}

.contentContainer {
    padding: 32px 0 38px;
}

.plusShape {
    height: 4px;
    width: 100%;
    margin: 0;
    position: absolute;
    border: none;
    background-color: #707070;
}

.plusShape:nth-child(2) {
    rotate: 90deg;
}

.plusMent {
    font-size: 18px;
    color: #A2A2A2;
}

/* AdminLogin */
.login {
    width: 640px;
    height: 376px;
}

.middleLogin {
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
}

.loginTextbox {
    width: 320px;
    height: 48px;
    padding: 14px 18px;
    border: 1px solid #DDDDDD;
    color: #222222;
    font-size: 18px;
    font-weight: 500;
}

.loginTextbox:focus,
.loginTextbox:not(:placeholder-shown) {
    border: 1px solid #DC140A;
    outline: none;
}

/* Main */
.introMainBtn {
    position: absolute;
    top: -80px;
    width: 200px;
}

.main {
    width: 1300px;
    height: 795px;
}

.mainContent {
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    align-items: center;
}

.mainImgBox {
    width: 860px;
    height: 402px;
    background-color: #F4F4F4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.mainImgBox img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 860px;
    height: 402px;
    display: block;
}

.mainPlus {
    width: 50px;
    height: 50px;
    position: relative;
}

.mainButtonBox {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.fncMainBtn {
    width: 104px;
    height: 48px;
}

input[type=file] {
    display: none;
}

/* Production */
.introProductionBtn {
    width: 200px;
    position: absolute;
    bottom: -96px;
    left: 50%;
    transform: translateX(-50%);
}

.production {
    width: 1300px;
    height: 856px;
}

.middleProductionWrap {
    display: flex;
    gap: 20px;
}

.middleProduction:nth-child(1) {
    width: 270px;
}

.middleProduction:nth-child(2) {
    width: 930px;
}

.productionContent {
    padding: 32px 0 40px;
    display: flex;
    flex-direction: column;
    gap: 23px;
}

.productionListContainer {
    width: 100%;
    height: 445px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-x: hidden;
    overflow-y: overlay;
}

.productionListItem {
    height: 48px;
    border: 1px solid #DDDDDD;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    cursor: pointer;
    position: relative;
    transition: 0.3s ease-out;
}

.productionListItem:hover {
    background-color: #DDDDDD;
}

.productionListItem:hover > .deleteItemBtn {
    opacity: 1;
    visibility: visible;
}


.selected {
    background-color: #DDDDDD;
}

.selected > .productionListItemNum,
.selected > .productionListItemName {
    color: #1C1C1D;
}

.selected > .deleteItemBtn {
    opacity: 1;
    visibility: visible;
}

.productionListItemNum {
    color: #A2A2A2;
    font-size: 18px;
    font-weight: 600;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #DDDDDD;
    flex-shrink: 0;
}

.productionListItemName {
    color: #A2A2A2;
    font-size: 18px;
    padding: 0 22px;
    max-width: 222px;
    flex-shrink: 0;
}

.productionListItemName > span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
}

.fncProductionBtn {
    height: 48px;
}

.productionContent2 {
    display: flex;
    gap: 40px;
    padding: 32px 0 35px;
}

.productionContentInfo {
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 534px;
    overflow-x: hidden;
    overflow-y: auto;
}

.productionContentImage {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.contentInfoContainer {
    width: 400px;
}

.contentImageContainer {
    width: 310px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.productionContentTheme {
    color: #434343;
    font-size: 20px;
    font-weight: 600;
    width: 110px;
    position: relative;
}

.productionContentMent {
    color: #A2A2A2;
    font-size: 14px;
    letter-spacing: -0.14px;
}

.infoItemContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.infoItem {
    border: 1px solid #DDDDDD;
    height: 48px;
    display: flex;
    padding: 0 16px;
    align-items: center;
    gap: 40px;
}
.infoItem > span {
    color: #a2a2a2;
    font-size: 16px;
    font-weight: 500;
    position: relative;
}

.infoItem > input[type=text] {
    outline: none;
    border: transparent;
    height: 40px;
    font-size: 18px;
    color: #434343;
    font-weight: 600;
}

.infoItem input[type=text]::placeholder {
    color: #A2A2A2;
    font-weight: 400;
}

.required {
    display: block;
    font-size: 9px;
    font-weight: 500;
    color: #E63131;
    position: absolute;
    top: 0;
    right: -24px;
}

.imfoImage {

}

.productionImage {
    width: 310px;
    height: 465px;
    background-color: #F4F4F4;
    display: flex;
    flex-direction: column;
    gap: 13px;
    justify-content: center;
    align-items: center;
    position: relative;
}

.productionPlus {
    width: 37px;
    height: 37px;
    position: relative;
    cursor: pointer;
}
.productionImage > span {
    font-size: 13px;
    padding: 0 29px;
    text-align: center;
}

.deleteItemBtn {
    background-color: #E63131;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 40%;
    right: 28px;
    transform: translateY(-40%);
    rotate: 45deg;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s ease-out;
    cursor: pointer;
}

.deleteItemBtn > div {
    width: 10px;
    height: 10px;
    position: relative;
    margin-top: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.deleteItemBtn > div > hr {
    width: 100%;
    height: 1px;
    background-color: #FFFFFF;
    margin: 0;
    border: none;
    position: absolute;
}

.deleteItemBtn > div > hr:nth-child(2) {
    rotate: 90deg;
}

.productionImage > img {
    position: absolute;
    top: 0;
    width: 310px;
    z-index: 1;
    cursor: pointer;
}

.isalbum,
.isalbum > img {
    height: 310px;
}

/* NEWS */
.introNewsBtn {
    margin: 40px auto 0 auto;
}

.news {
    width: 1300px;
    height: 979px;
}

.notice {
    width: 1300px;
    height: 939px;
}

.newsContent {
    margin: 32px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 24px 20px;
}

.newsContentItem {
    width: 600px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.newsContentThemeContainer {
    display: flex;
    align-items: center;
    gap: 32px;
}

.newsContentTheme {
    color: #434343;
    font-size: 20px;
    font-weight: 600;
    position: relative;
}

.newsContentexplain {
    color: #A2A2A2;
    font-size: 14px;
}

.requirednews {
    display: block;
    font-size: 9px;
    font-weight: 500;
    color: #E63131;
    position: absolute;
    top: 0;
    right: -28px;
}

.newsContentItem input[type=text] {
    outline: none;
    border: 1px solid #DDDDDD;
    height: 48px;
    padding: 0 16px;
    color: #434343;
    font-size: 18px;
    font-weight: 600;
}

.newsContentItem input[type=text]::placeholder {
    color: #A2A2A2;
    font-weight: 400;
}

.newsContentItem textarea {
    height: 160px !important;
    outline: none;
    border: 1px solid #DDDDDD;
    padding: 14px 16px;
    color: #434343;
    font-size: 14px;
    resize: none;
}

.newsImgContainer {
    padding: 32px 0 40px 0;
    display: flex;
    gap: 24px;
}

.newsImgContainer > .newsContentThemeContainer {
    flex-direction: column;
    align-items: baseline;
    gap: 7px;
}

.newsImgAddBtn {
    width: 104px;
    height: 48px;
    font-size: 14px;
}

.newsUploadImg {
    border-radius: 8px;
    background-color: #F4F4F4;
    padding: 16px 20px;
    display: flex;
    justify-content: space-between;
    gap: 8px;
}

.newsUploadImg > span {
    color: #1C1C1D;
    font-size: 14px;
}

.newsUploadImgDelBtn {
    background-color: #DDDDDD;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    rotate: 45deg;
    cursor: pointer;
}

.newsUploadImgDelBtn > hr {
    margin: 0;
    width: 8px;
    height: 1px;
    border: none;
    background-color: #707070;
    position: absolute;
    top: 50%;
    left: 50;
}

.newsUploadImgDelBtn > hr:nth-child(2) {
    rotate: 90deg;
}

.newsFileContainer {
    margin-top: -16px;
    padding-bottom: 40px;
    display: flex;
    gap: 24px;
}

.newsFileContainer > .newsContentThemeContainer {
    flex-direction: column;
    align-items: baseline;
    gap: 7px;
}

/* More */

.introMoreContainer {
    margin-top: -40px;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
}

/* Artist */
.introArtistContainer {
    margin-top: 40px;
    display: flex;
    justify-content: center;
}

.artist {
    width: 1300px;
    max-height: 95vh;
}

.middleArtistWrap {
    display: flex;
    gap: 20px;
}

.middleArtist:nth-child(1) {
    width: 270px;
}

.middleArtist:nth-child(2) {
    width: 930px;
}

.artistContent {
    padding: 32px 0 40px;
    display: flex;
    flex-direction: column;
    gap: 23px;
}

.artistListContainer {
    width: 100%;
    height: 445px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-x: hidden;
    overflow-y: overlay;
}

.artistListItem {
    height: 48px;
    border: 1px solid #DDDDDD;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    cursor: pointer;
    position: relative;
    transition: 0.3s ease-out;
}

.artistListItem:hover {
    background-color: #DDDDDD;
}

.artistListItem:hover > .deleteItemBtn {
    opacity: 1;
    visibility: visible;
}


.selected > .artistListItemNum,
.selected > .artistListItemName {
    color: #1C1C1D;
}

.artistListItemNum {
    color: #A2A2A2;
    font-size: 18px;
    font-weight: 600;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #DDDDDD;
    flex-shrink: 0;
}

.artistListItemName {
    color: #A2A2A2;
    font-size: 18px;
    padding: 0 22px;
    max-width: 222px;
    flex-shrink: 0;
}

.artistListItemName > span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
}

.artistContent2 {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 32px 0 35px;
    height: 660px;
    overflow-y: scroll;
}

.artistContent2::-webkit-scrollbar {
    display: none;
}

.artistContentInfo {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 400px;
}

.artistContentInfoContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.artistContentTheme {
    color: #434343;
    font-size: 20px;
    font-weight: 600;
    width: 110px;
    position: relative;
}

.aritstContentExplain {
    color: #A2A2A2;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    flex-direction: column;
}

.aritstContentExplain > span {
    display: block;
}

.artistContentImage {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.artistImgContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 14px;
}

.artistImgItem {
    background-color: #F4F4F4;
    width: 80px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
}

.artistImgItem:nth-child(1) > .artistImgWithDel > img {
    border: 1px solid #000000;
}

.artistImgWithDel > img {
    width: 80px;
    height: 120px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    cursor: pointer;
    transition: 0.3s ease-out;
    color: transparent;
}

.artistImgWithDel:hover > img {
    filter: brightness(30%);
}

.artistImgWithDel:hover > .deleteItemBtn {
    visibility: visible;
    opacity: 1;
}

.artistImgWithDel > .deleteItemBtn {
    transform: translateY(0);
    z-index: 5;
}

.itemNameTag {
    height: 24px;
    padding: 0 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #1C1C1D;
    color: #F4F4F4;
    font-size: 14px;
    font-weight: 500;
    z-index: 2;
}

.artistImgItem:nth-child(n+2) > .itemNameTag {
    max-width: 24px;
}

.artistPlus {
    width: 16px;
    height: 16px;
    position: relative;
    margin-top: 16px;
}

.plusShape2 {
    height: 2px;
    width: 100%;
    margin: 0;
    position: absolute;
    border: none;
    background-color: #707070;
}

.plusShape2:nth-child(2) {
    rotate: 90deg;
}

.artistContentCareer {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 13px;
}

.artistCareerContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 16px 24px;
}

.artistCareerItem {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.artistCareerItem > span {
    color: #434343;
    font-size: 16px;
    font-weight: 600;
}

.artistCareerTextarea {
    width: 440px;
    height: 93px;
    padding: 16px;
    border: 1px solid #DDDDDD;
}

.artistCareerTextarea > textarea {
    width: 408px;
    height: 61px;
    color: #434343;
    font-size: 14px;
    outline: none;
    resize: none;
    border: none;
    line-height: 22px;
}

.contentImageContainer2 {
    width: 330px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.productionContentTheme2 {
    color: #434343;
    font-size: 20px;
    font-weight: 600;
    width: 126px;
    position: relative;
}

.producerImage {
    width: 160px;
    height: 160px;
    background-color: #F4F4F4;
    display: flex;
    flex-direction: column;
    gap: 13px;
    justify-content: center;
    align-items: center;
    position: relative;
}

.producerImage > img {
    position: absolute;
    top: 0;
    width: 160px;
    z-index: 1;
    cursor: pointer;
}

.productionContentInfo2 {
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 279px;
    overflow-x: hidden;
    overflow-y: auto;
}

.productionContent3 {
    display: flex;
    gap: 0 134px;
    padding: 32px 0 35px;
    flex-wrap: wrap;
}

.productionContent3 > hr {
    width: 100%;
    height: 1px;
    border: none;
    background-color: #DDDDDD;
    margin: 16px 0;
}

.productionContent3 .infoItem {
    width: 440px;
}

.productionContent3 .infoItem input[type=text] {
    width: 310px;
}
.productionContentMent2 {
    display: block;
    color: #A2A2A2;
    font-size: 14px;
    letter-spacing: -0.14px;
    margin-top: 8px;
}

.producerTextContainer {
    width: 904px;
    display: flex;
    gap: 24px;
    margin-top: 16px;
}

.producerItem {
    width: 440px;
}

.producerItem > span {
    display: block;
    color: #434343;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
}

.producerItem > textarea {
    width: 440px;
    height: 93px;
    color: #434343;
    font-size: 14px;
    outline: none;
    resize: none;
    border: 1px solid #DDDDDD;
    line-height: 22px;
    letter-spacing: -0.14px;
    padding: 16px;
}

.producerPlus {
    width: 37px;
    height: 37px;
    position: relative;
    transform: translateY(18px);
    cursor: pointer;
}